<template>
  <div
    class="px-page grid-default gap-y-20 md:gap-y-20"
    :class="{
      'md:bg-neutral-50': data.backgroundColor === 'Gray',
      'bg-purple-100 py-10 md:py-0': data.backgroundColor === 'Purple',
      'my-20': !data.removeSpacing
    }"
  >
    <div class="col-span-12 md:order-2 md:col-span-4 md:col-start-8 md:pt-24">
      <Heading v-if="data.title" generic :size="4" class="mb-3 md:mb-9">{{ data.title }}</Heading>
      <Type v-if="data.html" :level="2" class="mb-7">
        <div v-html="data.html"></div>
      </Type>
      <div v-if="Array.isArray(data.link) && data.link.length">
        <Button
          :label="data.link[0].label"
          :to="
            data.link[0].internalLink !== null
              ? localePathByType(
                  data?.link[0].internalLink!.__typename as string,
                  data?.link[0].internalLink!.slug as string,
                  data?.link[0].internalLink!.__typename === ('ArticleRecord' as string) &&
                    data?.link[0].internalLink!.category.slug
                )
              : data.link[0].externalLink
          "
          variant="link"
          class="font-medium"
          :icon="{
            name: 'circle-chevron-right-general',
            className: 'w-8px h-8px'
          }"
        />
      </div>
    </div>
    <div class="col-span-12 md:order-1 md:col-span-6">
      <div class="px-page-pull-left">
        <Picture
          :data="data?.media!"
          loading="lazy"
          class="aspect-5/4 sm:max-w-[60vw] md:max-w-[85%] [&>img]:w-full"
          clipType="logo"
          clipDirection="right"
          sizes="(min-width: 1000px) 40.7vw, (min-width: 640px) 60vw, calc(100vw - 32px)"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { SectionEducationalRecord } from '~/types/generated'

const { localePathByType } = useLocalePathByType()

defineProps({
  data: {
    type: Object as PropType<SectionEducationalRecord>,
    default: () => {}
  }
})
</script>
